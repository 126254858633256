
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "BillNoticeModals",
  methods: {
    redirectTo(path: string) {
      this.$emit("closeModal");
      this.$router.push("/customer-service");
    },
  },
});
