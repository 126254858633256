
import { defineComponent, PropType } from "vue";
import axios from "axios";

import VueBarcode from "@chenfengyuan/vue-barcode";
import UnpaidInvoiceModal from "@/components/MyOrders/UnpaidInvoiceModal.vue";
// import CancelOrderModal from "@/components/MyOrders/CancelOrderModal.vue";
import { Modal } from "bootstrap";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface ccoin {
  id: string;
  expireDate: string;
  amount: number;
  itemName: string;
}

interface detail {
  id: string;
  ai: number; // 380
  amount: number;
  coin: number;
  paymentType: string; // "ATM" ;
  invoiceData: invoice;
  invoiceId: number | null;
  expireDate: string; // "2022-12-24 00:00:00"
  paymentDate: number | null;
  barcode: Array<string>;
  bankCode: string; // "005"
  vAccount: string;
  createdDate: string;
}

interface invoice {
  carrier_type: string; // "donate"
  code: string;
  email: string;
  invoice_type: string; // "B2C"
  item_name: string;
  name: string;
  phone: string;
  company: string;
  address: string;
  postal_code: number;
  tax_id: number;
}

export default defineComponent({
  name: "UnpaidBillListCCoin",
  components: {
    VueBarcode,
    UnpaidInvoiceModal,
    // CancelOrderModal,
  },
  props: {
    ccoins: {
      type: Array as PropType<Array<ccoin>>,
      required: true,
    },
    isPending: {
      type: Boolean,
      required: true,
    },
    display: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tooltips: {} as { [key: string]: boolean },
      details: {} as { [key: string]: detail },
      detailIsPending: false,
      invoiceModal: null as any,
      invoice: {
        code: "1995",
        email: "test0203@gmail.com",
        invoice_type: "B2C",
        item_name: "C幣自訂方案",
        name: "",
      } as invoice,
      notice: {
        BARCODE: [
          "● 同一繳費條碼請勿重覆繳款。",
          "● 本視窗繳費條碼僅適用本期繳費單使用。",
          "● 繳費條碼已內含手續費，超商店員不會在結帳時收手續費。",
          "● 繳費帳單有任何問題請與OurScool線上客服或電洽04-22657611，服務時間：週一至週五 9:00-18:00",
        ],
        ATM: [
          "● 同一虛擬帳號請勿重覆繳款。",
          "● 請持此帳號至ATM繳款，請務必確認帳號代碼及繳款金額",
          "● 繳費帳單有任何問題請與OurScool線上客服或電洽04-22657611，服務時間：週一至週五 9:00-18:00",
        ],
      } as { [key: string]: Array<string> },
    };
  },
  methods: {
    paymentTypeMap(type: string) {
      const map = {
        ATM: "ATM轉帳",
        BARCODE: "超商條碼",
        Credit: "信用卡",
      } as { [key: string]: string };

      return map[type];
    },
    openModal(id: string) {
      // 取得資料
      axios
        .get(`${serverUrl}paydata/detail/${id}?type=coin&isInvoice=1`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
          }
        })
        .catch(() => {
          console.log("error");
        });

      this.invoiceModal.show();
    },
    // Toggle Expand Button
    toggleExpand(id: string) {
      // 取得資料
      this.detailIsPending = true;

      axios
        .get(`${serverUrl}paydata/detail/${id}?type=coin&isInvoice=0`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.details[`${id}`] = res.data.data;
          }

          // console.log(this.details[id]);
          // console.log(this.details);

          // 整理發票資訊
          this.invoice = this.details[id].invoiceData;

          this.detailIsPending = false;
        })
        .catch(() => {
          console.log("error");
          this.detailIsPending = false;
        });

      // 展開詳細資料
      const target = document.getElementById(
        `ccoin-collapse-control-${id}`
      )! as HTMLElement;

      if (target.getAttribute("aria-expanded") === "false") {
        target.style.transform = "rotate(0deg)";
      } else {
        target.style.transform = "rotate(180deg)";
      }
    },
    // See More Button
    toggleExpand2(id: string) {
      // 取得資料
      axios
        .get(`${serverUrl}paydata/detail/${id}?type=coin&isInvoice=0`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.details[`${id}`] = res.data.data;
          }
        })
        .catch(() => {
          console.log("error");
        });

      // 展開詳細資料
      const target = document.getElementById(
        `ccoin-see-more-control-${id}`
      )! as HTMLElement;
      console.log(target);

      const icon = document.getElementById(
        `ccoin-see-more-control-icon-${id}`
      )! as HTMLElement;
      // console.log(icon);

      const text = document.getElementById(
        `ccoin-see-more-control-text-${id}`
      )! as HTMLElement;
      // console.log(text);

      if (target.getAttribute("aria-expanded") === "false") {
        icon.classList.remove("bi-chevron-up");
        icon.classList.add("bi-chevron-down");
        text.innerHTML = "更多資訊";
      } else {
        icon.classList.remove("bi-chevron-down");
        icon.classList.add("bi-chevron-up");
        text.innerHTML = "更少資訊";
      }
    },
    downloadPdf(pdf: string, expireDate: string) {
      axios({
        url: pdf, //your url
        method: "GET",
        responseType: "blob", // important
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      }).then((response) => {
        const fileName = `${expireDate.slice(0, 4)}年${expireDate.slice(
          5,
          7
        )}月份`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}繳費單.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  emits: ["showNoticeModal"],
  mounted() {
    //  初始化Tooltip
    // var tooltipTriggerList = [].slice.call(
    //   document.querySelectorAll('[data-bs-toggle="tooltip"]')
    // );

    // tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   return new Tooltip(tooltipTriggerEl, {
    //     container: "body",
    //     trigger: "hover",
    //   });
    // });

    // 初始化Modal
    this.invoiceModal = new Modal(
      document.getElementById("unpaid-invoice-modal") as HTMLElement
    );

    // 整理詳細資料
    this.ccoins.forEach((ccoin) => {
      this.details[`${ccoin.id}`] = {} as detail;
      this.tooltips[`${ccoin.id}`] = false;
    });
  },
});
