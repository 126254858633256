
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrecautionsModal",
  components: {},
  props: ["id"],
  data() {
    return {};
  },
  methods: {
    gradientEffect() {
      let target = document.querySelector(`#fade-content`)! as HTMLElement;
      let scrollBody = document.querySelector(`#scroll-body`)! as HTMLElement;
      // 這裡必須延遲 10ms 是為了避免 DOM 剛生成時無法正確取得高度

      setTimeout(() => {
        if (
          scrollBody.scrollTop + scrollBody.clientHeight + 5 >=
          scrollBody.scrollHeight
        ) {
          target.classList.remove("fade-content");
          target.classList.add("no-fade-content");
        } else {
          target.classList.add("fade-content");
          target.classList.remove("no-fade-content");
        }
        scrollBody.addEventListener("scroll", () => {
          // 多 +5 是為了避免誤差造成判斷錯誤
          if (
            scrollBody.scrollTop + scrollBody.clientHeight + 5 >=
            scrollBody.scrollHeight
          ) {
            target.classList.remove("fade-content");
            target.classList.add("no-fade-content");
          } else {
            target.classList.add("fade-content");
            target.classList.remove("no-fade-content");
          }
        });
      }, 10);
    },
  },
});
