
import { defineComponent } from "vue";
import axios from "axios";
import mixins from "@/mixins";
import Bus from "@/utils/Bus";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface order {
  insts: number;
  order: number;
  orderId: string;
  pricePerInst: string;
  time: number;
  totalPrice: string;
}

interface detail {
  insts: number;
  order: number;
  orderId: string;
  pricePerInst: string;
  products: Array<{ id: string; name: string }>;
  sr: { name: string; no: string };
  time: number;
  totalPrice: string;
}

export default defineComponent({
  name: "OrdersList",
  mixins: [mixins],
  props: {
    installment: {
      type: Boolean,
      require: true,
    },
  },
  emit: ["showInstallmentPage"],
  data() {
    return {
      isPending: false,
      detailIsPending: false,
      orderData: [] as Array<order>,
      // orderDetail: [] as Array<detail>,
      orderDetail: {} as { [key: string]: detail },
    };
  },
  methods: {
    toggleExpand(order: number) {
      // 取得資料
      this.detailIsPending = true;

      axios
        .get(`${serverUrl}paydata/orders?orderId=${order}`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.orderDetail[`${order}`] = res.data.data.orders[0];
            // console.log(this.orderDetail[`${order}`]);
          }
          this.detailIsPending = false;
        })
        .catch(() => {
          this.detailIsPending = false;
        });

      // 展開詳細資料
      const target = document.getElementById(
        `collapse-control-${order}`
      )! as HTMLElement;

      if (target.getAttribute("aria-expanded") === "false") {
        target.style.transform = "rotate(0deg)";
      } else {
        target.style.transform = "rotate(180deg)";
      }
    },
    goToInstallmentPage(status: boolean) {
      this.$emit("showInstallmentPage", status);
    },
  },
  mounted() {
    this.isPending = true;
    axios
      .get(`${serverUrl}paydata/orders`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.orderData = res.data.data.orders;

          this.isPending = false;
        }
      })
      .catch(() => {
        this.isPending = false;
      });
  },
});
