
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import OrderNoticeModal from "@/components/MyOrders/OrderNoticeModal.vue";
import BillNoticeModal from "@/components/MyOrders/BillNoticeModal.vue";

export default defineComponent({
  name: "MyOrdersAltCategoryCard",
  components: {
    OrderNoticeModal,
    BillNoticeModal,
  },
  data() {
    return {
      orderNoticeModal: null as any,
      billNoticeModal: null as any,
    };
  },
  props: ["activeCategory"],
  emit: ["showInstallmentPage"],
  methods: {
    openModal(activeCategory: string) {
      if (activeCategory === "order") {
        this.goToInstallmentPage(true);
      } else if (activeCategory === "bill") {
        this.billNoticeModal.show();
      }
    },
    closeModal(activeCategory: string) {
      if (activeCategory === "bill") {
        this.billNoticeModal.hide();
      }
    },
    goToInstallmentPage(status: boolean) {
      this.$emit("showInstallmentPage", status);
    },
  },
  mounted() {
    // 初始化Modal
    this.orderNoticeModal = new Modal(
      document.getElementById("my-order-notice-modal")! as HTMLElement
    );
    this.billNoticeModal = new Modal(
      document.getElementById("my-bill-notice-modal")! as HTMLElement
    );
  },
});
