
import { defineComponent } from "vue";

export default defineComponent({
  name: "BarcodeNoticeModal",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      notice: {
        BARCODE: [
          "同一繳費條碼請勿重覆繳款。",
          "本視窗繳費條碼僅適用本期繳費單使用。",
          "繳費條碼已內含手續費，超商店員不會在結帳時收手續費。",
          "繳費帳單有任何問題請與OurScool線上客服或電洽04-22657611，服務時間：週一至週五 9:00-18:00",
        ],
        ATM: [
          "同一虛擬帳號請勿重覆繳款。",
          "請持此帳號至ATM繳款，請務必確認帳號代碼及繳款金額",
          "繳費帳單有任何問題請與OurScool線上客服或電洽04-22657611，服務時間：週一至週五 9:00-18:00",
        ],
      } as { [key: string]: Array<string> },
    };
  },
});
