
import { defineComponent, PropType } from "vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "DeleteOrderModal",
  methods: {
    deleteOrder() {
      this.$emit("deleteOrder");
    },
  },
  emits: ["deleteOrder"],
});
