
import { defineComponent, PropType } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";

import VueBarcode from "@chenfengyuan/vue-barcode";

// import { Tooltip } from "bootstrap";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface bill {
  id: string;
  date: string;
  // expireDate: string;
  amount: number;
  status: string;
  payWay: string | null;
  barcode: boolean;
  paymentDeadlineDate: string;
  isDeposit: boolean;
}

interface detail {
  id: string;
  amount: number;
  barcode: Array<string>;
  expireDate: number;
  pdf: string;
  paymentDate: string;
  bankCode: string;
  invoiceId: string;
}

export default defineComponent({
  name: "UnpaidBillListOrder",
  components: {
    VueBarcode,
  },
  props: {
    bills: {
      type: Array as PropType<Array<bill>>,
      required: true,
    },
    isPending: {
      type: Boolean,
      required: true,
    },
    display: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [mixins],
  data() {
    return {
      details: {} as { [key: string]: detail },
      tooltips: {} as { [key: string]: boolean },
      notice: [
        "● 同一繳費條碼請勿重覆繳款。",
        "● 本視窗繳費條碼僅適用本期繳費單使用。",
        "● 繳費條碼已內含手續費，超商店員不會在結帳時收手續費。",
        "● 繳費帳單有任何問題請與OurScool線上客服或電洽04-22657611，服務時間：週一至週五 9:00-18:00",
        "● 超商條碼、ATM轉帳與現場收現繳款需 3 個工作天入帳。",
      ],
    };
  },
  methods: {
    // Toggle Expand Button
    toggleExpand(id: string) {
      // 取得資料
      axios
        .get(`${serverUrl}paydata/detail/${id}?type=class&isInvoice=0`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.details[`${id}`] = res.data.data;
          }
        })
        .catch(() => {
          console.log("error");
        });

      // 展開詳細資料
      const target = document.getElementById(
        `order-collapse-control-${id}`
      )! as HTMLElement;

      if (target.getAttribute("aria-expanded") === "false") {
        target.style.transform = "rotate(0deg)";
      } else {
        target.style.transform = "rotate(180deg)";
      }
    },
    // See More Button
    toggleExpand2(id: string) {
      // 取得資料
      axios
        .get(`${serverUrl}paydata/detail/${id}?type=class&isInvoice=0`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.details[`${id}`] = res.data.data;
          }
        })
        .catch(() => {
          console.log("error");
        });

      // 展開詳細資料
      const target = document.getElementById(
        `order-see-more-control-${id}`
      )! as HTMLElement;
      // console.log(target);

      const icon = document.getElementById(
        `order-see-more-control-icon-${id}`
      )! as HTMLElement;
      // console.log(icon);

      const text = document.getElementById(
        `order-see-more-control-text-${id}`
      )! as HTMLElement;
      // console.log(text);

      if (target.getAttribute("aria-expanded") === "false") {
        icon.classList.remove("bi-chevron-up");
        icon.classList.add("bi-chevron-down");
        text.innerHTML = "更多資訊";
      } else {
        icon.classList.remove("bi-chevron-down");
        icon.classList.add("bi-chevron-up");
        text.innerHTML = "更少資訊";
      }
    },
    downloadPdf(pdf: string, expireDate: number) {
      axios({
        url: pdf, //your url
        method: "GET",
        responseType: "blob", // important
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      }).then((response) => {
        const fileName = this.timestampToDate(expireDate, 2).slice(0, 8);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}繳費單.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  emits: ["showNoticeModal"],
  mounted() {
    // 初始化Tooltip
    // var tooltipTriggerList = [].slice.call(
    //   document.querySelectorAll('[data-bs-toggle="tooltip"]')
    // );

    // tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   return new Tooltip(tooltipTriggerEl, {
    //     container: "body",
    //     trigger: "hover",
    //   });
    // });
    // 整理詳細資料
    this.bills.forEach((bill) => {
      this.details[`${bill.id}`] = {} as detail;
      this.tooltips[`${bill.id}`] = false;
    });
  },
});
