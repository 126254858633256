
import { defineComponent } from "vue";
import axios from "axios";

import { Tooltip, Modal } from "bootstrap";

import Filter from "@/components/MyOrders/Filter.vue";
import DeleteOrderModal from "@/components/MyOrders/DeleteOrderModal.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface order {
  id: string;
  date: string;
  amount: number;
  itemName: string;
  type: string; // 前端加上的
}

interface ccoin {
  id: number;
  type: string;
  product: string;
  pStatus: string;
  pDate: number;
  pAmount: number;
  pMethod: string;
  dueDate: number;
  ccoin: number;
}

interface detail {
  id: string;
  ai: number; // 380
  amount: number;
  coin: number;
  paymentType: string; // "ATM" ;
  invoiceId: number | null;
  expireDate: string; // "2022-12-24 00:00:00"
  paymentDate: number | null;
  barcode: Array<string>;
  bankCode: string; // "005"
  vAccount: string;
  createdDate: string;
}

export default defineComponent({
  name: "InvalidBillList",
  components: {
    Filter,
    DeleteOrderModal,
  },
  props: ["status", "total"],
  data() {
    return {
      isPending: false,
      detailIsPending: false,
      ccoins: [] as Array<order>,
      details: {} as { [key: string]: detail },
      tooltips: {} as { [key: string]: boolean },
      deleteOrderId: "",
      deleteModal: null as any,
    };
  },
  methods: {
    paymentTypeMap(type: string) {
      const map = {
        ATM: "ATM轉帳",
        BARCODE: "超商條碼",
        Credit: "信用卡",
      } as { [key: string]: string };

      return map[type];
    },
    // Toggle Expand Button
    toggleExpand(id: string) {
      // 取得資料
      this.detailIsPending = true;

      axios
        .get(`${serverUrl}paydata/detail/${id}?type=coin&isInvoice=0`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.details[`${id}`] = res.data.data;
          }

          this.detailIsPending = false;
        })
        .catch(() => {
          // console.log("error");
          this.detailIsPending = false;
        });

      // 展開詳細資料
      const target = document.getElementById(
        `invalid-collapse-control-${id}`
      )! as HTMLElement;

      if (target.getAttribute("aria-expanded") === "false") {
        target.style.transform = "rotate(0deg)";
      } else {
        target.style.transform = "rotate(180deg)";
      }
    },
    // 展開確認刪除Modal
    openModal(id: string) {
      this.deleteOrderId = "";
      this.deleteOrderId = id;
      this.deleteModal.show();
    },
    deleteOrder() {
      // Delete Order
      this.isPending = true;
      axios
        .delete(`${serverUrl}paydata/order/${this.deleteOrderId}`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.isPending = false;
        })
        .catch((err) => {
          console.log("error");
          this.isPending = false;
        });

      // Close Modal
      this.deleteModal.hide();

      // Reload
      this.fetchData();
    },
    fetchData() {
      this.isPending = true;
      axios
        .get(`${serverUrl}paydata?billStatus=fail`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data.data.coins);
            this.ccoins = res.data.data.coins;

            this.isPending = false;
          }
        })
        .catch(() => {
          this.isPending = false;
        });

      // 整理詳細資料
      this.ccoins.forEach((item) => {
        this.details[`${item.id}`] = {} as detail;
        this.tooltips[`${item.id}`] = false;
      });
    },
  },
  mounted() {
    // 初始化Tooltip
    // var tooltipTriggerList = [].slice.call(
    //   document.querySelectorAll('[data-bs-toggle="tooltip"]')
    // );

    // tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   return new Tooltip(tooltipTriggerEl, {
    //     container: "body",
    //     trigger: "hover",
    //   });
    // });

    // 初始化Modal
    this.deleteModal = new Modal(
      document.getElementById("delete-order-modal") as HTMLElement
    );

    // 已失效List
    this.fetchData();
  },
});
