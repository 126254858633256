
import { defineComponent, PropType } from "vue";
import { Modal } from "bootstrap";
import axios from "axios";
import PrecautionsModal from "@/components/MyOrders/PrecautionsModal.vue";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

type InstallmentInfo = {
  previous_total_price: number;
  total_price: number;
  insts_table: {
    period: string;
    amount: number;
  }[];
  new_order_total_price: number;
  created_at: string;
};
export default defineComponent({
  name: "InstallmentView",
  components: { PrecautionsModal },
  mixins: [],
  props: {
    installmentInfo: {
      type: Object as PropType<InstallmentInfo>,
      required: true,
    },
  },
  emits: ["showInstallmentPage"],
  data() {
    return {
      modalId: "PrecautionsModal",
      myModal: null as any,
    };
  },
  watch: {},
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    this.myModal = new Modal(
      document.getElementById(this.modalId) as HTMLElement
    );
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    goToInstallmentPage(status: boolean) {
      this.$emit("showInstallmentPage", status);
    },
    showModal() {
      this.myModal.show();
      (this.$refs.PrecautionsModal as any).gradientEffect();
    },
  },
});
