
import { defineComponent, PropType } from "vue";
import { Tooltip } from "bootstrap";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface invoice {
  carrier_type: string; // "donate"
  code: string;
  email: string;
  invoice_type: string; // "B2C"
  item_name: string;
  name: string;
  phone: string;
  company: string;
  address: string;
  postal_code: number;
  tax_id: number;
  // customerName: string;
  // customerMail: string;
  // invoiceTo: string; // B 統編發票 ; C 電子發票
  // buyer: string | null; // 非營業人則為 null
  // companyId: number | null; // 非營業人則為 null
}

export default defineComponent({
  name: "UnpaidInvoiceModal",
  props: {
    invoice: {
      type: Object as PropType<invoice>,
      required: true,
    },
  },
});
