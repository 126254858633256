
import { defineComponent } from "vue";

// Interface
import {} from "@/types/models";

// Axios
import axios from "axios";

// Mixins
import mixins from "@/mixins/index";

// Template
import Template13 from "@/views/templates/Template13.vue";
import CategoryCard from "@/components/MyOrders/CategoryCard.vue";
import AltCategoryCard from "@/components/MyOrders/AltCategoryCard.vue";
import OrdersListNew from "@/components/MyOrders/OrdersListNew.vue";
import BillListNew from "@/components/MyOrders/BillListNew.vue";
import InstallmentView from "@/components/MyOrders/InstallmentView.vue";

type InstallmentInfo = {
  previous_total_price: number;
  total_price: number;
  insts_table: {
    period: string;
    amount: number;
  }[];
  new_order_total_price: number;
  created_at: string;
};

export default defineComponent({
  name: "LearningReport",
  components: {
    Template13,
    CategoryCard,
    AltCategoryCard,
    OrdersListNew,
    BillListNew,
    InstallmentView,
  },
  mixins: [mixins],
  data() {
    return {
      subpaths: [
        { name: "我的訂單", link: "/my-orders" },
        { name: "我的繳費單", link: "/my-orders?category=bill" },
      ],
      activeCategory: "bill",
      showInstallment: false as boolean,
      installment: false as boolean,
      installmentInfo: {} as InstallmentInfo,
    };
  },
  watch: {
    pageRoute() {
      this.activeCategory = `${this.$route.query.category}`;
    },
    activeCategory() {
      this.subpaths[1].name =
        this.activeCategory === "bill" ? "我的繳費單" : "訂單明細";
      this.subpaths[1].link = `/my-orders?category=${
        this.activeCategory === "bill" ? "bill" : "order"
      }`;
    },
  },
  computed: {
    pageRoute() {
      return this.$route.query.category;
    },
  },
  methods: {
    updateCategory(category: string) {
      this.activeCategory = category;
    },
    showInstallmentPage(status: boolean) {
      this.showInstallment = status;
    },
  },
  mounted() {
    // URL Params: category
    if (this.$route.query.category) {
      const entryPoint = `${this.$route.query.category}`;
      this.activeCategory = entryPoint;
    }
    const serverUrl = process.env.VUE_APP_SERVER;
    const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
    axios
      .get(`${serverUrl}paydata/installment`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          res.data.data !== null
            ? (this.installment = true)
            : (this.installment = false);
          this.installmentInfo = res.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
});
