
import { defineComponent, PropType } from "vue";

interface invoice {
  company: string | null;
  taxId: number | null;
  amount: number;
  customerNo: string;
  invoiceDate: string;
  invoiceNumber: string;
  invoiceType: string;
  isAllowance: boolean;
  name: string | null;
  orderId: string;
  product: string;
  randomNumber: string;
}

export default defineComponent({
  name: "PaidInvoiceModal",
  props: {
    info: {
      type: Object as PropType<invoice>,
      required: true,
    },
  },
});
