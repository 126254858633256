
import { defineComponent } from "vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "BillListSwitch",
  props: ["status"],
  emits: ["changeStatus"],
});
