
import { defineComponent } from "vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "BillListFilter",
  props: ["status", "filter", "total"],
  data() {
    return {
      orderChecked: false,
      ccoinChecked: false,
    };
  },
  emits: ["changeFilter"],
  methods: {
    changeFilter(type: string) {
      this.$emit("changeFilter", type);
    },
  },
});
