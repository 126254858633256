import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58fd5d54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  style: {"background-color":"#f6f6f6"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryCard = _resolveComponent("CategoryCard")!
  const _component_AltCategoryCard = _resolveComponent("AltCategoryCard")!
  const _component_OrdersListNew = _resolveComponent("OrdersListNew")!
  const _component_BillListNew = _resolveComponent("BillListNew")!
  const _component_Template13 = _resolveComponent("Template13")!
  const _component_InstallmentView = _resolveComponent("InstallmentView")!

  return (!_ctx.showInstallment)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Template13, null, {
          "left-column": _withCtx(() => [
            _createVNode(_component_CategoryCard, {
              onChangeCategory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateCategory($event))),
              activeCategory: _ctx.activeCategory
            }, null, 8, ["activeCategory"])
          ]),
          "right-column": _withCtx(() => [
            _createVNode(_component_AltCategoryCard, {
              onChangeCategory: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCategory($event))),
              activeCategory: _ctx.activeCategory,
              onShowInstallmentPage: _ctx.showInstallmentPage
            }, null, 8, ["activeCategory", "onShowInstallmentPage"]),
            (_ctx.activeCategory === 'order')
              ? (_openBlock(), _createBlock(_component_OrdersListNew, {
                  key: 0,
                  activeCategory: _ctx.activeCategory,
                  installment: _ctx.installment,
                  onShowInstallmentPage: _ctx.showInstallmentPage
                }, null, 8, ["activeCategory", "installment", "onShowInstallmentPage"]))
              : _createCommentVNode("", true),
            (_ctx.activeCategory === 'bill')
              ? (_openBlock(), _createBlock(_component_BillListNew, {
                  key: 1,
                  activeCategory: _ctx.activeCategory
                }, null, 8, ["activeCategory"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_InstallmentView, {
          onShowInstallmentPage: _ctx.showInstallmentPage,
          installmentInfo: _ctx.installmentInfo
        }, null, 8, ["onShowInstallmentPage", "installmentInfo"])
      ]))
}