
import { defineComponent } from "vue";
import axios from "axios";
import mixins from "@/mixins";
import { Modal } from "bootstrap";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
const currentTimestamp = Date.now();
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

// Components
import Filter from "@/components/MyOrders/Filter.vue";
import CCoinList from "@/components/MyOrders/UnpaidBillListCCoin.vue";
import OrderList from "@/components/MyOrders/UnpaidBillListOrder.vue";
import UpcomingList from "@/components/MyOrders/UnpaidBillListUpcoming.vue";
import PMethodNoticeModal from "@/components/MyOrders/BarcodeNoticeModal.vue";

interface bill {
  id: string;
  date: string;
  amount: number;
  isDeposit?: boolean | undefined;
  payment_method?: string | null;
}

interface billToPay {
  id: string;
  date: string;
  amount: number;
  status: string;
  payWay: string | null;
  barcode: boolean;
  paymentDeadlineDate: string;
  isDeposit: boolean;
}

interface ccoin {
  id: string;
  date: string;
  amount: number;
  itemName: string;
}

export default defineComponent({
  name: "UnpaidBillList",
  mixins: [mixins],
  components: {
    Filter,
    CCoinList,
    OrderList,
    UpcomingList,
    PMethodNoticeModal,
  },
  props: ["status", "total"],
  data() {
    return {
      filter: { class: true, coin: true },
      isPending: false,
      bills: [] as Array<bill>,
      ccoins: [] as Array<ccoin>,
      billsToPay: [] as Array<bill>,
      billsDeposit: [] as Array<bill>,
      billsInFuture: [] as Array<bill>,
      totalUnpaid: 0,
      type: "B",
      pMethodModal: null as any,
    };
  },
  computed: {
    NoData() {
      if (this.isPending === false) {
        if (
          this.filter.class === true &&
          this.filter.coin === false &&
          this.bills.length === 0
        ) {
          return true;
        } else if (
          this.filter.class === false &&
          this.filter.coin === true &&
          this.ccoins.length === 0
        ) {
          return true;
        } else if (
          ((this.filter.class === false && this.filter.coin === false) ||
            (this.filter.class === true && this.filter.coin === true)) &&
          this.bills.length === 0 &&
          this.ccoins.length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return "isPending";
      }
    },
  },
  methods: {
    showNoticeModal(type: string) {
      this.type = type;
      this.pMethodModal.show();
    },
    displayData(type: string) {
      if (
        (this.filter.class === true && this.filter.coin === true) ||
        (this.filter.class === false && this.filter.coin === false)
      ) {
        return true;
      } else if (this.filter.class === false && this.filter.coin === true) {
        if (type === "coin") {
          return true;
        } else {
          return false;
        }
      } else if (this.filter.class === true && this.filter.coin === false) {
        if (type === "class") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    changeFilter(type: string) {
      if (type === "class") this.filter.class = !this.filter.class;
      if (type === "coin") this.filter.coin = !this.filter.coin;
    },
  },
  mounted() {
    // 初始化Modal
    this.pMethodModal = new Modal(
      document.getElementById("barcode-notice-modal")! as HTMLElement
    );

    this.isPending = true;

    // 待付款List
    axios
      .get(`${serverUrl}paydata?billStatus=pending`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.totalUnpaid = 0;
          this.billsToPay = [];
          this.billsInFuture = [];
          this.billsDeposit = [];
          this.bills = res.data.data.bills;
          this.ccoins = res.data.data.coins;
          let total = 0;
          if (this.bills.length > 0) {
            this.bills.forEach((ele) => {
              const expireDate = new Date(ele.date);
              const expireDateTimestamp = expireDate.getTime();
              const expireYear = expireDate.getFullYear();
              const expireMonth = expireDate.getMonth() + 1;
              let tmp = {} as billToPay;
              tmp.id = ele.id;
              tmp.amount = ele.amount;
              tmp.date = ele.date;
              tmp.isDeposit = ele.isDeposit!;
              tmp.payWay =
                ele.payment_method === undefined || ele.payment_method === null
                  ? null
                  : ele.payment_method;
              if (tmp.payWay === "現金") {
                tmp.payWay = "現場收現";
              } else if (tmp.payWay === "匯款") {
                tmp.payWay = "帳戶匯款";
              }
              tmp.barcode = !ele.id.includes("OSC");
              tmp.paymentDeadlineDate = ele.date.substring(ele.date.length - 2);
              if (ele.isDeposit === true) {
                tmp.status = "審核中";
                this.billsDeposit.push(tmp);
              } else if (currentTimestamp > expireDateTimestamp) {
                // 逾期未繳
                tmp.status = "逾期未繳";
                this.billsToPay.push(tmp);
              } else if (
                expireYear === currentYear &&
                expireMonth === currentMonth
              ) {
                // 當期應繳
                tmp.status = "本期應繳";
                this.billsToPay.push(tmp);
              } else {
                // 未達繳費月份
                tmp.status = "待付款";
                this.billsInFuture.push(tmp);
              }
              if (tmp.status !== "審核中") {
                total += ele.amount;
              }
            });
          }
          if (this.ccoins.length > 0) {
            this.ccoins.forEach((ele) => {
              total += ele.amount;
            });
          }
          this.totalUnpaid = total;
          this.isPending = false;
        }
      })
      .catch(() => {
        this.isPending = false;
      });
  },
});
