
import { defineComponent } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";
import { Modal } from "bootstrap";

// Components
import Filter from "@/components/MyOrders/Filter.vue";
import PaidInvoiceModal from "@/components/MyOrders/PaidInvoiceModal.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface order {
  id: string;
  date: string;
  amount: number;
  itemName: string;
  type: string; // 前端加上的
}

interface invoice {
  company: string | null;
  taxId: number | null;
  amount: number;
  customerNo: string;
  invoiceDate: string;
  invoiceNumber: string;
  invoiceType: string;
  isAllowance: boolean;
  name: string | null;
  orderId: string;
  product: string;
  randomNumber: string;
}

interface detail {
  id: string;
  ai: number; // 380
  amount: number;
  coin: number;
  paymentType: string; // "ATM" ;
  invoiceId: number | null;
  expireDate: string; // "2022-12-24 00:00:00"
  paymentDate: number | null;
  barcode: Array<string>;
  bankCode: string; // "005"
  vAccount: string;
  createdDate: string;
}

export default defineComponent({
  name: "PaidBillList",
  components: {
    PaidInvoiceModal,
    Filter,
  },
  props: ["status", "total"],
  mixins: [mixins],
  data() {
    return {
      filter: { class: true, coin: true },
      isPending: false,
      detailIsPending: false,
      bills: [] as Array<order>,
      ccoins: [] as Array<order>,
      datas: [] as Array<order>,
      details: {} as { [key: string]: detail },
      tooltips: {} as { [key: string]: boolean },
      invoiceModal: null as any,
      invoice: {
        company: "",
        taxId: 0,
        customerNo: "",
        isAllowance: true, // true: 已折讓
        invoiceNumber: "",
        invoiceDate: "",
        invoiceType: "", // B2B 營業人 ; B2C 非營業人
        name: "", // 非營業人則為 null
        randomNumber: "",
        orderId: "",
        product: "",
        amount: 0,
      } as invoice,
      display: [],
    };
  },
  methods: {
    displayData() {
      if (this.filter.class === false && this.filter.coin === true) {
        return this.ccoins;
      } else if (this.filter.class === true && this.filter.coin === false) {
        return this.bills;
      } else {
        return this.datas;
      }
    },
    changeFilter(type: string) {
      if (type === "class") this.filter.class = !this.filter.class;
      if (type === "coin") this.filter.coin = !this.filter.coin;
    },
    openModal(id: number, type: string) {
      axios
        .get(`${serverUrl}paydata/detail/${id}?type=${type}&isInvoice=1`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            this.invoice = res.data.data;
          }
        })
        .catch(() => {
          console.log("error");
        });

      this.invoiceModal.show();
    },
    paymentTypeMap(type: string) {
      const map = {
        ATM: "ATM轉帳",
        BARCODE: "超商條碼",
        Credit: "信用卡",
        manually: "內部儲值",
      } as { [key: string]: string };

      return map[type];
    },
    toggleExpand(id: string, type: string) {
      // 取得資料
      this.detailIsPending = true;

      axios
        .get(`${serverUrl}paydata/detail/${id}?type=${type}&isInvoice=0`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.details[`${id}`] = res.data.data;
          }

          this.detailIsPending = false;
        })
        .catch(() => {
          console.log("error");
          this.detailIsPending = false;
        });

      // 展開詳細資料
      const target = document.getElementById(
        `collapse-control-${id}`
      )! as HTMLElement;

      if (target.getAttribute("aria-expanded") === "false") {
        target.style.transform = "rotate(0deg)";
      } else {
        target.style.transform = "rotate(180deg)";
      }
    },
  },
  mounted() {
    // 初始化Tooltip
    // var tooltipTriggerList = [].slice.call(
    //   document.querySelectorAll('[data-bs-toggle="tooltip"]')
    // );

    // tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   return new Tooltip(tooltipTriggerEl, {
    //     container: "body",
    //     trigger: "hover",
    //   });
    // });

    this.isPending = true;

    // 已付款List
    axios
      .get(`${serverUrl}paydata?billStatus=finish`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data.data);

          const billDatas = res.data.data.bills;
          if (billDatas.length > 0) {
            for (let index = 0; index < billDatas.length; index++) {
              this.bills[index] = billDatas[index];
              this.bills[index].type = "class";
            }
          }

          const coinDatas = res.data.data.coins;
          if (coinDatas.length > 0) {
            for (let index = 0; index < coinDatas.length; index++) {
              this.ccoins[index] = coinDatas[index];
              this.ccoins[index].type = "coin";
            }
          }

          this.bills = billDatas;
          this.ccoins = coinDatas;

          this.datas = this.bills.concat(this.ccoins);
          this.datas = this.datas.sort(function (a, b) {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });

          this.isPending = false;
          // console.log(this.datas);
        }
      })
      .catch(() => {
        this.isPending = false;
      });

    // 整理詳細資料
    this.datas.forEach((item) => {
      this.details[`${item.id}`] = {} as detail;
      this.tooltips[`${item.id}`] = false;
    });

    // 初始化Modal
    this.invoiceModal = new Modal(
      document.getElementById("paid-invoice-modal") as HTMLElement
    );
  },
});
