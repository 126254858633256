
import { defineComponent } from "vue";
import Switch from "@/components/MyOrders/Switch.vue";
import BillNotice from "@/components/MyOrders/BillNotice.vue";
import PaidBillList from "@/components/MyOrders/PaidBillList.vue";
import UnpaidBillList from "@/components/MyOrders/UnpaidBillList.vue";
import InvalidBillList from "@/components/MyOrders/InvalidBillList.vue";

import axios from "axios";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
const currentTimestamp = Date.now();
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

interface order {
  id: string;
  date: string;
  amount: number;
  itemName: string;
  isDeposit: boolean;
}

export default defineComponent({
  name: "BillList",
  components: {
    Switch,
    BillNotice,
    PaidBillList,
    UnpaidBillList,
    InvalidBillList,
  },
  props: ["activeCategory"],
  data() {
    return {
      isPending: false,
      totalUnpaid: 0,
      status: "unpaid",
      filter: { class: false, coin: false },
    };
  },
  methods: {
    changeStatus(status: string) {
      this.status = status;
    },
    changeFilter(type: string) {
      // console.log(type);
      if (type === "class") this.filter.class = !this.filter.class;
      if (type === "coin") this.filter.coin = !this.filter.coin;
    },
  },
  mounted() {
    this.isPending = true;

    // 待付款List
    axios
      .get(`${serverUrl}paydata?billStatus=pending`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then((res) => {
        // if (res.status == 200) {
        //   this.totalUnpaid = 0;

        //   const bills = res.data.data.bills;
        //   const ccoins = res.data.data.coins;

        //   this.totalUnpaid = bills.length + ccoins.length;
        //   this.isPending = false;
        // }

        if (res.status == 200) {
          const bills = res.data.data.bills;
          const ccoins = res.data.data.coins;

          let total = 0;

          if (bills.length > 0) {
            bills.forEach((ele: order) => {
              const expireDate = new Date(ele.date);
              const expireDateTimestamp = expireDate.getTime();
              const expireYear = expireDate.getFullYear();
              const expireMonth = expireDate.getMonth() + 1;

              if (ele.isDeposit === true) return;
              if (
                currentTimestamp > expireDateTimestamp ||
                (expireYear === currentYear && expireMonth === currentMonth)
              ) {
                total += 1;
              }
            });
          }

          total += ccoins.length;

          this.totalUnpaid = total;

          this.isPending = false;
        }
      })
      .catch(() => {
        this.isPending = false;
      });
  },
});
