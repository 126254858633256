
import { defineComponent, PropType } from "vue";
import { Tooltip } from "bootstrap";
import Bus from "@/utils/Bus";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "OrderNoticeModal",
});
